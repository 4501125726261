import React, { memo, ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';
import Button from '_Ui/Button/button';
import { SyntheticOnClick } from '_interfaces/OnClick';

const propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  distance: PropTypes.string,
  small: PropTypes.bool,
};

type Button = 'small' | 'big' | 'default';
type Props = InferProps<typeof propTypes> & { onClick: SyntheticOnClick };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`;

const ActionContainer: React.FC<Props> = function ActionContainer({
  children,
  text,
  onClick,
  _id,
  small,
}): ReactElement {
  return (
    <Wrapper>
      {children}
      <Button onClick={onClick} small={small} text={text} color="primary" dataInfo={_id} />
    </Wrapper>
  );
};

ActionContainer.propTypes = {};
ActionContainer.defaultProps = {
  text: 'Place holder',
  small: true,
};

export default ActionContainer;
