import React, { ReactElement } from 'react';
import withHooks from 'Utils/withHooks.jsx';
import { useStepper } from '_stores/stepperStore';
import PropTypes, { InferProps } from 'prop-types';
import services from 'Utils/_const/services';
import TrainingSelection from '../Stepper/trainings';
import ServiceSelection from '../Stepper/services';

const propTypes = { service: PropTypes.string.isRequired };

type Props = InferProps<typeof propTypes>;

const Selections: React.FC<Props> = React.memo(function Selections({ service }): ReactElement {
  return service === services.dogTraining ? <TrainingSelection /> : <ServiceSelection />;
});

const mapHooksToProps = () => {
  const {
    state: { service },
  } = useStepper();
  return { service };
};

Selections.propTypes = propTypes;

export default withHooks(mapHooksToProps)(Selections);
