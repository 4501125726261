import React, { ReactElement, useState } from 'react';
import { MDBCol } from 'mdbreact';
import PropTypes, { InferProps } from 'prop-types';
import ReactCalendar from 'react-calendar';
import Notes from '_Ui/Notes/Notes';
import classNames from 'classnames';
import timeConversions from 'Utils/timeConversions';
import ParentContainer from '_Ui/styledContainers/parentContainer';
import styles from './calendar.module.scss';

const propTypes = { onClick: PropTypes.func.isRequired };
type Props = InferProps<typeof propTypes> & {
  events: Array<{ date: string | Date; [propName: string]: any }>;
  selectedDate: Date;
};

const Calendar: React.FC<Props> = React.memo(function Calendar({
  onClick,
  events,
  selectedDate,
}): ReactElement {
  const [currentDate, setDate] = useState<Date>(selectedDate);
  return (
    <ParentContainer>
      <MDBCol className={classNames('mx-auto', styles)} id="react-calendar">
        <ReactCalendar
          className="mx-auto my-5 w-75"
          defaultView="month"
          view="month"
          defaultValue={selectedDate}
          onClickDay={(date) => {
            onClick(date);
            setDate(date);
          }}
          tileClassName={({ date }) => {
            for (let i = 0; i < events.length; i++) {
              const event = events[i];
              const eventDate = new Date(event.startDate);

              if (eventDate.toDateString() === date.toDateString()) {
                return styles.redFont;
              }
            }
            return '';
          }}
        />
      </MDBCol>
      <MDBCol size="12" sm="6" className="mx-auto">
        <Notes
          message={`Selected Date: ${timeConversions.getDate(currentDate)}`}
          className="text-center"
        />
      </MDBCol>
    </ParentContainer>
  );
});

Calendar.propTypes = propTypes;

export default Calendar;
