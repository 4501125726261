import React, { ReactElement } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MDBContainer, MDBCol } from 'mdbreact';

import NavBar from 'Components/NavBar/navBar';
import { StepperProvider } from '_stores/stepperStore';
import Text from 'Components/Text/text';
import { CheckoutProvider } from '_stores/checkoutStore';
import OrderConfirmation from 'Components/OrderConfirmation/orderConfirmation';
import { EventsProvider } from '_stores/eventsAndStoresStore';
import VisibleCustomerDashboard from 'Containers/Customer/visibileCustomerDashboard';
import { CustomerDashboardProvider } from '_stores/customerDashboard';

import Stepper from './Stepper/Stepper';
import styles from './app.module.scss';

// interface Props
interface Props {
  title?: string;
}

export default function App({ title }: Props): ReactElement {
  return (
    <main className={styles.main}>
      {/* <Create /> */}
      <NavBar />
      <Switch>
        <MDBContainer style={{ marginTop: '100px' }}>
          <Route exact path="/">
            <Redirect to="/booking" />
          </Route>
          <Route exact path="/booking">
            <Stepper />
          </Route>
          <Route exact path="/check-booked-reservations">
            <CustomerDashboardProvider>
              <VisibleCustomerDashboard />
            </CustomerDashboardProvider>
          </Route>
          <Route exact path="/order-confirmation">
            <OrderConfirmation />
          </Route>
        </MDBContainer>
      </Switch>
    </main>
  );
}
