import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ParentContainer from '_Ui/styledContainers/parentContainer';
import { Reservation } from '_interfaces/Events/Reservation';
import ListTitle from '_Ui/ListTitle/listTitle';
import ReservationInfo from './reservationInfo';

const propTypes = {
  reservation: PropTypes.array.isRequired,
  reservationSelected: PropTypes.bool,
};

type Props = InferProps<typeof propTypes> & {
  reservations: Array<Reservation>;
  onClick(reservationId: string): void;
};

const ReservationList: React.FC<Props> = React.memo(function ReservationList({
  reservations,
  onClick,
}): ReactElement {
  return (
    <ParentContainer align={'center'} templateColumn={'470px'}>
      {reservations && reservations.length > 0 && (
        <>
          <ListTitle text={'Select Reservation Time'} />
          {reservations.map((reservation: Reservation) => (
            <ReservationInfo
              key={reservation._id}
              reservation={reservation}
              onClick={() => onClick(reservation._id)}
            />
          ))}
        </>
      )}
    </ParentContainer>
  );
});

ReservationList.propTypes = propTypes;
ReservationList.defaultProps = { reservation: [] };

export default ReservationList;
