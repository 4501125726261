// import
import withHooks from 'Utils/withHooks.jsx';
import { Reservation } from '_interfaces/Events/Reservation';
import { useEventStore } from '_stores/eventsAndStoresStore';
import { useStepper } from '_stores/stepperStore';
import ReservationList from 'Components/reservationInfo/reservationList';
import _stepper from 'Utils/_const/stepper';

const mapHooksToProps = (): {
  reservations: Array<Reservation>;
  onClick(reservationId: string): void;
  reservationSelected: boolean;
} => {
  const {
    selectReservation,
    state: { reservations },
  } = useEventStore();

  const { select, reservationSelected } = useStepper();

  const onClick = (reservationId: string) => {
    select(_stepper.reservationSelected);
    selectReservation(reservationId);
  };

  return { reservations, onClick, reservationSelected };
};

export default withHooks(mapHooksToProps)(ReservationList);
