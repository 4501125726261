import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import Errors from '_Ui/Errors/errors';
import styles from './fieldInput.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  arialabel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  constraints: PropTypes.object.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

type ReactHookErrors = {
  [name: string]: { message: string };
};

interface Errors {
  errors: ReactHookErrors;
}

type Props = InferProps<typeof propTypes> & Errors & React.HTMLProps<HTMLInputElement>;

const FieldInput: React.FC<Props> = React.memo(function Fieldinput({
  label,
  id,
  type,
  arialabel,
  name,
  register,
  onChange,
  constraints,
  errors,
  placeholder,
}): ReactElement {
  return (
    <div className="md-form">
      <input
        onChange={onChange}
        type={type || 'text'}
        aria-label={label}
        id={id}
        name={name}
        className={classNames('form-control active', styles.input)}
        ref={register && register(constraints)}
        aria-disabled="false"
        placeholder={placeholder}
        // style={{ fontFamily: 'Arial FontAwesome' }}
      />
      <label
        aria-labelledby={arialabel}
        className={classNames('active', styles.label)}
        htmlFor={id}>
        {label}
      </label>
      {errors && errors[name] && <Errors message={errors[name].message} />}
    </div>
  );
});

FieldInput.propTypes = propTypes;
export default FieldInput;
