import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { MDBIcon } from 'mdbreact';
import { capitalize } from 'lodash';
import { BookedReservation } from '_interfaces/Customer/BookedReservation';
import { CancelReservationTypes } from '_interfaces/Customer/DashboardApi';
import { Reservation } from '_interfaces/Events/Reservation';
import { Toast } from '_interfaces/Toast';
import withToast from 'Containers/Toast/toast';
import DataTable from 'Components/DataTable/dataTable';
import Button from '_Ui/Button/button';
import timeConversions from 'Utils/timeConversions';
import consts from './consts';

const propTypes = {
  bookedReservations: PropTypes.array.isRequired,
  loadingData: PropTypes.bool.isRequired,
};

interface IBookedReservation extends BookedReservation {
  time?: Date;
}

type Props = InferProps<typeof propTypes> & {
  bookedReservations: Array<{ bookingInfo: IBookedReservation; reservation: Reservation } | []>;
  cancelReservation: CancelReservationTypes;
  newToast: Toast;
};

/**
 * data to transform
 * "time": "2021-01-30T23:30:00.000Z",
 * "referenceId": "Klxy_s6",
 * "reservationStatus": "good",
 * "customerName": "Tim Tran",
 * "cost": 4500,
 * "redemption": false,
 * "paymentId": "ET195071",
 * "paymentTag": "4615131781",
 * "paymentRef": 1
 *
 * columns example:[
 *  {
 *    label: 'Ref Id',
 *    field: 'refId',
 *  }
 * ]
 *
 * Rows Example:
 * {
 *  'referenceId': 1,
 *  'redemption',
 *  'cost',
 *  'reservationStatus',
 *  'time',
 *  'handler: <MDBBtn color="purple" size="sm">Button</MDBBtn>
 * },
 *
 */

const BookedReservationItem: React.FC<Props> = React.memo(function BookedReservationItem({
  bookedReservations,
  cancelReservation,
  loadingData,
  newToast,
}): ReactElement {
  /**
   * columns data example:
   *
   */
  const columns = [
    {
      label: capitalize('Ref Id'),
      field: consts.REFERENCE_ID,
    },
    {
      label: 'Redeemed?',
      field: consts.REDEMPTION,
      width: 75,
    },
    {
      label: capitalize(consts.COST),
      field: consts.COST,
    },
    {
      label: capitalize(consts.NAME),
      field: consts.NAME,
    },
    {
      label: capitalize('location'),
      field: consts.LOCATION_ADDRESS,
    },
    { label: capitalize(consts.DATE), field: consts.DATE },
    {
      label: capitalize(consts.TIME),
      field: consts.TIME,
    },
    {
      label: capitalize(consts.HANDLE),
      field: consts.HANDLE,
    },
  ];

  const dataVals = bookedReservations.map(
    ({
      bookingInfo: { cost, time, paymentRef, ...adjustedBookingInfo },
    }: {
      bookingInfo: IBookedReservation;
      reservation: Reservation;
    }) => {
      const { _id, referenceId, name } = adjustedBookingInfo;
      const date = timeConversions.getDate(time);
      const timeAt = timeConversions.getTime(time);
      const redemption = adjustedBookingInfo.redemption ? (
        <MDBIcon icon="check" className="text-center green-text" />
      ) : (
        <MDBIcon far icon="times-circle" className="text-center red-text" />
      );

      return {
        ...adjustedBookingInfo,
        redemption,
        cost: `$${cost}`.slice(0, 3),
        date,
        time: timeAt,
        cancel: (
          <Button
            text="cancel"
            color="danger"
            className="btn--primary"
            onClick={async () => {
              const deleted = await cancelReservation(_id, referenceId, name);
              if (deleted) {
                newToast(
                  `We have cancelled your event reservation with id of ${referenceId} and event name: ${name}`,
                );
              }
            }}
            small
          />
        ),
      };
    },
  );

  return (
    <>
      {dataVals.length > 0 && (
        <DataTable loadingData={loadingData} data={dataVals} columns={columns} btn={false} />
      )}
    </>
  );
});

export default withToast({ WrappedComponent: BookedReservationItem, title: 'Reservation Cancelled!' });
