import { useEventStore } from '_stores/eventsAndStoresStore';
import withHooks from 'Utils/withHooks.jsx';
import { InterfaceEvents } from '_interfaces/Events/Events';
import Calendar from 'Components/Calendar/calendar';

interface Props {
  events: Array<InterfaceEvents>;
  selectedDate: Date;
  onClick(eventDate: Date): void;
}

const mapHooksToProps = (): Props => {
  const {
    filterByDate,
    state: { events, selectedDate },
  } = useEventStore();

  return { events, selectedDate, onClick: filterByDate };
};

export default withHooks(mapHooksToProps)(Calendar);
