import getEvents from '_requests/getEvents';
import produce from 'immer';
import { flatMap, flatten } from 'lodash';
import { EventsApi, State } from '_interfaces/Events/EventsApi';
import { Stores } from '_interfaces/Stores/Stores';
import { SyntheticOnClick, DivElementOnClick } from '_interfaces/OnClick';
import getStores from '_requests/getStores';
import getReservationByEvent from '_requests/getReservationByEvent';
import setCosts from './utils/setCosts';
import setInitialEvent from './utils/setInitialEvent';
import createStore from './createStore';
import assignStoresWithDistance from './utils/assignStoresWithDistance';
import filterStoreByCategory from './utils/filterStoreByCategory';

/**
 * state: {events, stores}
 */

interface Props {
  state: State;
  setState: any;
}

const initialState = async () => {
  try {
    const zipcode = localStorage.getItem('zipcode') || undefined;
    const { data } = await getStores();
    const stores = await assignStoresWithDistance(data, zipcode || '90505');
    const testEvents = flatMap(stores, (store) => store.paid_events);

    return {
      testEvents,
      stores,
      filteredStores: [],
      selectedStore: {},
      selectedEvent: {},
      selectedReservation: {},
      isSelectingStore: true,
      events: [],
      filteredEvents: [],
      reservations: [],
      zipcode,
      category: '',
      eventAttendanceMode: '',
      selectedDate: new Date(),
    };
  } catch (err) {
    console.error(err);
    // handle client side error
  }
};

const eventsAndStoreApi = ({ state, setState }: Props): EventsApi => {
  const selectEvent = async (eventId: string): Promise<void> => {
    try {
      const { events } = state;
      // Async to make request here.
      const { data } = await getReservationByEvent(eventId);
      const [event] = events.filter(({ _id }) => _id === eventId);
      const { cost, _id } = event;

      // save costs and event to localStorage
      setCosts(_id, cost);

      setState(
        produce((draft) => {
          draft.reservations = data;
          draft.selectedEvent = event;
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };

  const filterByDate = (eventDate: Date): void => {
    const { events, selectedDate } = state;
    const filteredEvents = events.filter(
      ({ startDate }) => eventDate.toDateString() === new Date(startDate).toDateString(),
    );

    setState(
      produce((draft) => {
        draft.filteredEvents = filteredEvents;
      }),
    );
  };

  const selectReservation = (reservationId: string): void => {
    const { reservations } = state;
    const [reservation] = reservations.filter(({ _id }) => _id === reservationId);
    setState(
      produce((draft) => {
        draft.selectedReservation = reservation;
      }),
    );
  };

  const filterStore = async ({ zipcode }: { zipcode: string }) => {
    // logic here to filter out based on closest location
    try {
      const { filteredStores } = state;
      const updatedStores = await assignStoresWithDistance(filteredStores, zipcode);
      localStorage.setItem('zipcode', zipcode);
      setState(
        produce((draft) => {
          draft.filteredStores = updatedStores;
          draft.zipcode = zipcode;
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Initialized with the lastest Store
   * If startDate is less than today's date, do not set.
   * else set to select day of first event
   * @
   */
  const selectStore: SyntheticOnClick = (event) => {
    const storeId = event.currentTarget.dataset.target;
    const { filteredStores, category, eventAttendanceMode } = state;
    const store = filteredStores.filter((s: Stores) => `${s._id}` === storeId);

    const events = store[0].paid_events;
    const { filteredEvents, nextEventDate } = setInitialEvent(
      events,
      category,
      eventAttendanceMode,
    );

    setState(
      produce((draft) => {
        draft.events = events;
        draft.selectedStore = store[0];
        draft.isSelectingStore = false;
        draft.filteredEvents = filteredEvents;
        draft.selectedDate = nextEventDate;
      }),
    );
  };

  const _filterStoreByCategory: DivElementOnClick = (event): void => {
    const { stores } = state;
    const { name } = event.currentTarget.dataset;
    const filteredStores = filterStoreByCategory(name, stores);

    setState(
      produce((draft) => {
        draft.filteredStores = filteredStores;
        draft.category = name;
      }),
    );
  };

  const resetStateOnEdit = (selectedObject: string): void => {
    setState(
      produce((draft) => {
        draft[selectedObject] = {};
      }),
    );
  };

  return {
    state,
    filterByDate,
    filterStore,
    selectStore,
    selectEvent,
    selectReservation,
    resetStateOnEdit,
    filterStoreByCategory: _filterStoreByCategory,
  };
};

const [EventsProvider, useEventStore] = createStore(eventsAndStoreApi, initialState());

export { EventsProvider, useEventStore };
