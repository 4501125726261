/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DashboardApi } from '_interfaces/Customer/DashboardApi';
import { ILoading } from '_interfaces/Loading';
import withLoading from 'Containers/Loading/loading';
import Form from 'Components/Form/form';
import BookedReservationItem from 'Components/Customer/bookedReservation';
import FieldInput from 'Components/FieldInput/fieldInput';
import Text from 'Components/Text/text';
import ParentContainer from '_Ui/styledContainers/parentContainer';
import ActionContainer from '_Ui/styledContainers/actionContainer';
import Modal from '_Ui/Modal/modal';
import Notes from '_Ui/Notes/Notes';
import nameFields from 'Utils/fields/nameFields';

const CustomerDashboard: React.FC<
  DashboardApi & { setLoading: ILoading; setNotifications(message: string): void }
> = React.memo(function CustomerDashboard({
  state,
  cancelReservation,
  getReservations,
  setUser,
  toggle,
  setLoading,
}) {
  const { register, handleSubmit, setError, errors } = useForm();
  const [currentEmail, setEmail] = useState<string>('');
  const { email } = nameFields;
  const { isOpen, loadingData, bookedReservations } = state;

  const _onSubmit = handleSubmit(
    async (data): Promise<void> => {
      const inputEmail = data.email;
      const { error } = (await getReservations(inputEmail, setError)) || {};

      if (!error) {
        toggle(false);
        setUser(inputEmail);
        setEmail(inputEmail);
      }
    },
  );

  useEffect(() => {
    const localStorageEmail = localStorage.getItem('user_email') || '';
    (async () => {
      if (localStorageEmail) {
        const { error } = (await getReservations(localStorageEmail, setError)) || {};
        setEmail(localStorageEmail);
      }
    })();

    if (loadingData && localStorageEmail) {
      setLoading(true);
    } else {
      setTimeout(() => setLoading(false), 1500);
    }
  }, [loadingData]);

  return (
    <ParentContainer>
      <ActionContainer small={false} text={'Search Another Email'} onClick={() => toggle(true)}>
        {bookedReservations.length > 0 && (
          <Text className="d-block mb-4" text={`Current email: ${currentEmail}`} size="18px" />
        )}
      </ActionContainer>
      {bookedReservations.length < 1 && (
        <Notes message={'Not Reservations Found'} className="text-center" />
      )}
      {state.bookedReservations.length > 0 && (
        <>
          <BookedReservationItem
            bookedReservations={bookedReservations}
            cancelReservation={cancelReservation}
            loadingData={state.loadingData}
          />
        </>
      )}

      <Modal
        header="Find Booked Reservations"
        isOpen={isOpen}
        toggle={() => toggle(!isOpen)}
        button={true}>
        <Form id="customerEmail" buttonText="submit" _onSubmit={_onSubmit}>
          <FieldInput
            label={'Email'}
            id={'reservationEmail'}
            name={email}
            arialabel={email}
            register={register}
            errors={errors}
            constraints={{
              required: 'Please enter email',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
              shouldFocusError: true,
            }}
          />
        </Form>
      </Modal>
    </ParentContainer>
  );
});

export default withLoading({ WrappedComponent: CustomerDashboard });
