import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import ItemContainer from '_Ui/styledContainers/itemContainer';
import Text from 'Components/Text/text';

const propTypes = { message: PropTypes.string.isRequired, className: PropTypes.string };
type Props = InferProps<typeof propTypes>;

const Notes: React.FC<Props> = React.memo(function Notes({
  message,
  className,
}): ReactElement {
  return (
    <ItemContainer maxWidth="250" gridTemplateCol="1fr">
      <Text text={message} size="18" bold={true} className={classNames(className)} />
    </ItemContainer>
  );
});

Notes.propTypes = propTypes;

export default Notes;
