import React, { useState, useEffect } from 'react';
import { MDBAnimation } from 'mdbreact';
import styled from 'styled-components';
import { ReactComponentLike } from 'prop-types';
import Loader from 'Components/Loader/loader';
import Text from 'Components/Text/text';
import ParentContainer from '_Ui/styledContainers/parentContainer';
import styles from './loading.module.scss';

type loadingSize = 'big' | 'small' | 'default';

interface Props {
  WrappedComponent: ReactComponentLike;
  loadingMessage?: string;
  size?: loadingSize;
}

const Logo = styled.div`
  background-color: #dd1500;
  width: 25%;
  padding: 15px 20px;
  margin: auto;
`;

/**
 *
 * @param WrappedComponent
 * @param loadingMessage
 * @param size
 */
export default function withLoading({ WrappedComponent, loadingMessage, size }: Props): any {
  function WithLoading(props: any) {
    const [isLoading, setLoading] = useState<boolean>(false);

    return (
      <ParentContainer>
        {isLoading && (
          <div className={styles.loading}>
            <Loader size={size || 'default'} />
            <MDBAnimation type="pulse" count={3} duration={10} className="mx-auto text-center">
              <Logo>
                <img
                  src="https://cdn11.bigcommerce.com/s-nu7v662ya9/images/stencil/original/logo-01_1586382922__54064.original.png"
                  alt="Centinela Feed Logo"
                  className="img-fluid"
                />
              </Logo>
              <Text text={loadingMessage || 'Please wait...'} size="20px" className="d-block mt-5" />
            </MDBAnimation>
          </div>
        )}
        <WrappedComponent {...props} setLoading={setLoading} />
      </ParentContainer>
    );
  }
  return WithLoading;
}
