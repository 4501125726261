import produce from 'immer';
import swal from 'sweetalert2';
import { Errors } from '_interfaces/Errors';
import { DashboardApi, State, CancelReservationTypes } from '_interfaces/Customer/DashboardApi';
import cancelBookedReservation from '_requests/cancelBookedReservation';
import getBookedReservation from '_requests/getBookedReservation';

import createStore from './createStore';
// type OnClick = (event: React.MouseEvent<HTMLDivElement>) => void;
interface Props {
  state: State;
  setState: any;
}

const initialState = () => {
  const email = localStorage.getItem('user_email') || '';
  const isOpen = email === '';
  return {
    bookedReservations: [],
    isOpen,
    loadingData: true,
    email,
    error: false,
    errorMessage: '',
  };
};

const stepperApi = ({ state, setState }: Props): DashboardApi => {
  const setUser = (email: string): void => {
    setState(
      produce((draft) => {
        draft.email = email;
        draft.loadingData = true;
      }),
    );
    localStorage.setItem('user_email', email);
  };
  const getReservations = async (
    email: string,
    setError: any,
  ): Promise<{ error: boolean } | undefined> => {
    try {
      const { data } = await getBookedReservation(email);
      setState(
        produce((draft) => {
          draft.bookedReservations = data;
          draft.loadingData = false;
          draft.isOpen = false;
        }),
      );
      return { error: false };
    } catch (err) {
      console.error(err);
      const { message } = err.response.data || {};
      setError('email', {
        type: 'manual',
        message: message || 'Please enter email',
      });
      setState(
        produce((draft) => {
          draft.error = true;
          draft.errorMessage = message;
          draft.isOpen = true;
        }),
      );
      return { error: true };
    }
  };
  const cancelReservation: CancelReservationTypes = async (reservationId, referenceId, name) => {
    try {
      // const confirmed = await window.confirm(
      //   `Are you sure you want to cancel this booking with ID: ${referenceId} and event name of: ${name}`,
      // );
      const result = await swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: `You are about to cancel your reservation with id of "${referenceId}" and event name: "${name}"`,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Go back',
        showCancelButton: true,
      });

      if (result.value) {
        setState(
          produce((draft) => {
            draft.loadingData = true;
          }),
        );

        const { status } = await cancelBookedReservation(reservationId);
        if (status === 200) {
          const email = localStorage.getItem('user_email') || '';
          const { data } = (await getBookedReservation(email)) || [];

          setState(
            produce((draft) => {
              draft.bookedReservations = data;
              draft.loadingData = false;
            }),
          );
        }
        return true;
      }
    } catch (error) {
      const { message } = error.response.data;
      localStorage.removeItem('user_email');
      setState(
        produce((draft) => {
          draft.error = true;
          draft.errorMessage = message;
          draft.isOpen = true;
          draft.bookedReservations = [];
        }),
      );
    }
  };

  const toggle = (boolean: boolean) => {
    setState(
      produce((draft) => {
        draft.isOpen = boolean;
      }),
    );
  };

  return { state, cancelReservation, getReservations, setUser, toggle };
};

const [CustomerDashboardProvider, useCustomerDashboadStore] = createStore(
  stepperApi,
  initialState(),
);

export { CustomerDashboardProvider, useCustomerDashboadStore };

/**
 * I
 */
