import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import Text from 'Components/Text/text';
import styles from './stores.module.scss';

const propTypes = { name: PropTypes.string.isRequired, address: PropTypes.string.isRequired };
type Props = InferProps<typeof propTypes>;

const StoreInfo: React.FC<Props> = React.memo(function Storeinfo({ name, address }): ReactElement {
  return (
    <div className={styles.info}>
      <Text text={name} bold={true} size={'16px'} className={classNames(styles.header)} />
      <address className={styles.info__address}>
        <Text text={address} size={'14px'} />
      </address>
    </div>
  );
});

StoreInfo.propTypes = propTypes;
StoreInfo.defaultProps = { name: 'Placeholder', address: 'Placeholder' };

export default StoreInfo;
