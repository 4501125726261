import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';

const Div = styled.div`
  background-color: #fcebea;
  border-left: 4px solid #cc1f1a;
  font-size: 1.15rem;
  color: #cc1f1a;
  flex-basis: 100%;
  padding: 12px;
  margin-top: 5px;
`;

const propTypes = { message: PropTypes.string.isRequired };

type Props = InferProps<typeof propTypes>;

const Errors: React.FC<Props> = React.memo(function Errors({ message }) {
  return <Div>{message}</Div>;
});

Errors.propTypes = propTypes;

export default Errors;
