import { useEventStore } from '_stores/eventsAndStoresStore';
import withHooks from 'Utils/withHooks.jsx';
import { SyntheticOnClick, DivElementOnClick } from '_interfaces/OnClick';
import { InterfaceEvents } from '_interfaces/Events/Events';
import EventList from 'Components/Events/evenList';
import { Stores } from '_interfaces/Stores/Stores';
import SelectedEvent from 'Components/Events/selectedEvent';
import { useStepper } from '_stores/stepperStore';
import _stepper from 'Utils/_const/stepper';

interface Props {
  events: Array<InterfaceEvents>;
  stores: Array<Stores>;
  selectStore: DivElementOnClick;
  filterStore(zipcode: string): void;
  onClick(eventId: string): void;
}

/**
 * @return visible Events
 */
const mapHooksToEvents = (): Props => {
  const {
    filterStore,
    selectStore,
    selectEvent,
    state: { testEvents, stores, events, filteredEvents },
  } = useEventStore();
  const { select } = useStepper();

  const onClick = (eventId: string): void => {
    select(_stepper.eventSelected);
    selectEvent(eventId);
  };

  return { events: filteredEvents, stores, filterStore, selectStore, onClick };
};

/**
 * @return visible Selected event
 */
const mapSelectedEvent = () => {
  const {
    resetStateOnEdit,
    state: { selectedEvent },
  } = useEventStore();
  const { edit } = useStepper();

  return {
    event: selectedEvent,
    onClick: () => {
      edit(_stepper.eventSelected);
      resetStateOnEdit('reservations');
    },
  };
};

const VisibleEvents = withHooks(mapHooksToEvents)(EventList);
const VisibleSelectedEvent = withHooks(mapSelectedEvent)(SelectedEvent);

export { VisibleEvents, VisibleSelectedEvent };
