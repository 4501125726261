import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { InterfaceEvents } from '_interfaces/Events/Events';
import ParentContainer from '_Ui/styledContainers/parentContainer';
import ListTitle from '_Ui/ListTitle/listTitle';
import Notes from '_Ui/Notes/Notes';
import Event from './event';
import styles from './events.module.scss';

// Have to go back and include all the methods
// Inside the event Api interface
// There are too many retyping as the component grows...

const propTypes = {
  events: PropTypes.array.isRequired,
  stores: PropTypes.array,
};

type Props = InferProps<typeof propTypes> & InterfaceEvents & { onClick(eventId: string): void };

const EventList: React.FC<Props> = React.memo(function EventList({
  events,
  onClick,
}): ReactElement {
  return (
    <ParentContainer align={'center'} templateColumn={'700px'}>
      {events && events.length > 0 ? (
        <>
          <ListTitle text="Select classes" />
          {events.map((event: InterfaceEvents) => (
            <Event key={event._id} event={event} onClick={onClick} />
          ))}
        </>
      ) : (
        <Notes message="No events found on this date" className="text-center" />
      )}
    </ParentContainer>
  );
});

EventList.defaultProps = {
  events: [],
  stores: [],
};

export default EventList;
