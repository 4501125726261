import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { MDBSpinner, MDBCol } from 'mdbreact';

const propTypes = { loadingMessage: PropTypes.string };

type Props = InferProps<typeof propTypes & { size: 'small' | 'big' }>;

export default function Loader({ loadingMessage }: Props): ReactElement {
  return (
    <MDBCol size="4" className="m-auto text-center">
      {loadingMessage}
      <MDBSpinner red />
    </MDBCol>
  );
}

Loader.propTypes = propTypes;
