import { DashboardApi } from '_interfaces/Customer/DashboardApi';
import { useCustomerDashboadStore } from '_stores/customerDashboard';
import withHooks from 'Utils/withHooks.jsx';
import CustomerDashboard from './customerDashboard';

const mapHooksToProps = (): DashboardApi => {
  const { state, cancelReservation, getReservations, setUser, toggle } = useCustomerDashboadStore();

  return { state, cancelReservation, getReservations, setUser, toggle };
};

export default withHooks(mapHooksToProps)(CustomerDashboard);
