import React, { ReactElement } from 'react';
import fontSizes from 'Utils/_const/fontSizes';
import Text from 'Components/Text/text';
import PropTypes, { InferProps } from 'prop-types';

const propTypes = {
  text: PropTypes.string.isRequired,
};

type Props = InferProps<typeof propTypes>;

const ListTitle: React.FC<Props> = React.memo(function ListTitle({ text }): ReactElement {
  return <Text text={text} bold={true} size={fontSizes.title} />;
});

ListTitle.propTypes = propTypes;

export default ListTitle;
