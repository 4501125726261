const serverUrl = process.env.SERVER_URL;
export default {
  jsonserver: 'http://localhost:1337',
  paidEvents: `${serverUrl}/api/v1/cf-db/paid-events`,
  stores: `${serverUrl}/api/v1/cf-db/stores`,
  reservations: `${serverUrl}/api/v1/cf-db/reservations`,
  booking: `${serverUrl}/api/v1/cf-db/booking`,
  payment: `${serverUrl}/api/v1/other/payeezy`,
  googleMap: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
};
