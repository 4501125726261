import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { MDBSelect } from 'mdbreact';
import classNames from 'classnames';
import styles from './dropown.module.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  constraints: PropTypes.object,
  register: PropTypes.func,
};

type ReactHookErrors = {
  [name: string]: { message: string };
};

interface Errors {
  errors: ReactHookErrors;
}

type Props = InferProps<typeof propTypes> & { options: Array<any> } & Errors &
  React.HTMLProps<HTMLInputElement>;

const Dropdown = React.forwardRef<HTMLSelectElement, Props>(function Dropdown(
  { id, label, name, required, errors, options, register, constraints },
  ref,
): ReactElement {
  return (
    <div className="select-wrapper md-form">
      <select
        className={styles.select}
        ref={register && register(constraints)}
        name={name}
        id={id}
        required={required}>
        {options.map(
          ({ value, text }: { value: string | number; text: string | number }, i: number) => (
            <option key={i} value={value}>
              {text}
            </option>
          ),
        )}
      </select>

      <label aria-labelledby={label} className={classNames('active', styles.label)} htmlFor={id}>
        {label}
      </label>
      {errors && errors[name] && <div className={styles.error}>{errors[name].message}</div>}
    </div>
  );
});

Dropdown.propTypes = propTypes;

export default Dropdown;
