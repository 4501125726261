import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'react-calendar/dist/Calendar.css';
import './styles/css/index.css';
import ReactDOM from 'react-dom';

import { StepperProvider } from '_stores/stepperStore';
import { CheckoutProvider } from '_stores/checkoutStore';
import { EventsProvider } from '_stores/eventsAndStoresStore';
import App from './Containers/App';

ReactDOM.render(
  <Router>
    <StepperProvider>
      <CheckoutProvider>
        <EventsProvider>
          <App />
        </EventsProvider>
      </CheckoutProvider>
    </StepperProvider>
  </Router>,
  document.getElementById('app'),
);
