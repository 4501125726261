import StoreList from 'Components/Stores/storeList';
import SelectedStore from 'Components/Stores/selectedStore';
import withHooks from 'Utils/withHooks.jsx';
import { Stores } from '_interfaces/Stores/Stores';
import { useEventStore } from '_stores/eventsAndStoresStore';
import { useStepper } from '_stores/stepperStore';
import _stepper from 'Utils/_const/stepper';
import { SyntheticOnClick } from '_interfaces/OnClick';

// I can include it in here...

const mapVisibleStores = () => {
  const {
    selectStore,
    state: { filteredStores },
  } = useEventStore();
  const { select } = useStepper();

  const onClick = (e: SyntheticOnClick): void => {
    selectStore(e);
    select(_stepper.storeSelected);
  };

  return { onClick, stores: filteredStores };
};

const mapSelectedStore = () => {
  const {
    state: { selectedStore },
    resetStateOnEdit,
  } = useEventStore();
  const { edit } = useStepper();
  return {
    store: selectedStore,
    onClick: () => {
      edit(_stepper.storeSelected);
      resetStateOnEdit('events');
      resetStateOnEdit('reservations');
    },
  };
};

const VisibleStore = withHooks(mapVisibleStores)(StoreList);
const VisibleSelectedStore = withHooks(mapSelectedStore)(SelectedStore);

export { VisibleStore, VisibleSelectedStore };
