/* eslint-disable camelcase */
import { Checkout } from '_interfaces/Checkout/Checkout';
import { Payment } from '_interfaces/Checkout/Payment';
import { transform } from 'lodash';
import paymentFields from 'Utils/fields/paymentFields';
import nameFields from 'Utils/fields/nameFields';
import { Customer } from '_interfaces/Customer/Customer';

type IFieldType = string;

interface IFormData {
  firstName: IFieldType;
  lastName: IFieldType;
  email: IFieldType;
  phone: IFieldType;
  address: IFieldType;
  city: IFieldType;
  zipcode: IFieldType;
  state: IFieldType;
  cardNumber: IFieldType;
  cardExp: IFieldType;
  cvv: IFieldType;
}

interface IType {
  address: IFieldType;
  city: IFieldType;
  state?: IFieldType;
  zipcode?: IFieldType;
}

const paymentKeys = [paymentFields.cardExp, paymentFields.cardNumber, paymentFields.cvv];
const addressKeys = [nameFields.address, nameFields.city, nameFields.state, nameFields.zipcode];

// eslint-disable-next-line camelcase
const transformCustomer = (data: (IType & Customer) | any, reservation_id: string): Customer => {
  const { address, city, state, zipcode, firstName, lastName, ...customer } = data;
  customer.customerAddress = { address, city, state, zipcode };
  customer.customerName = `${firstName} ${lastName}`;
  return { ...customer, reservation_id };
};

export default (data: IFormData | any, reservation_id: string, cost: string | number): Checkout => {
  const payment = transform(
    data,
    (result: any, value, key) => {
      paymentKeys.forEach((paymentKey) => {
        if (key === paymentKey) {
          result[key] = value;
        }
      });
      return result;
    },
    {},
  );

  const customerAddress = transform(
    data,
    (result: any, value, key) => {
      addressKeys.forEach((addressKey) => {
        if (key === addressKey) {
          result.customerAddress = { [key]: value };
        }
      });
      return result;
    },
    {},
  );

  const customerData = transformCustomer(
    { ...data, reservation_id, customerAddress, customerName: '', reservationFor: '' },
    reservation_id,
  );

  return { customer: customerData, payment, cost, reservation_id };
};
