/* eslint-disable camelcase */
import produce from 'immer';
import { debounce } from 'lodash';
// import history from 'history/browser';
import bookNewEvent from '_requests/bookNewEvent';
import postPayment from '_requests/postPayment';
import { Checkout } from '_interfaces/Checkout/Checkout';
import { CheckoutApi, getInputValues } from '_interfaces/Checkout/CheckoutApi';
import { SyntheticOnClick, DivElementOnClick } from '_interfaces/OnClick';
import { EventPricing } from '_interfaces/Events/EventPricing';
import createStore from './createStore';
// import transformCustomer from './utils/transformCustomer';

const initialState = (): Checkout => {
  const eventPrice = JSON.parse(localStorage.getItem('event_price') || '{}');

  const pricing: EventPricing = eventPrice === '' ? 0 : eventPrice;

  return {
    customer: {
      firstName: '',
      lastName: '',
      customerName: '',
      customerAddress: { address: '', city: '', state: '', zipcode: '' },
      email: '',
      phone: '',
      reservationFor: '',
      reservation_id: pricing._id,
      cost: '',
    },
    payment: {
      cardNumber: '',
      expirationDate: '',
      cvv: '',
      billing: { address: '', city: '', state: '', zipcode: '' },
    },
    cost: parseFloat(`${pricing.cost}`).toFixed(2),
    reservation_id: pricing._id,
    error: false,
    errorMessage: '',
  };
};

const checkoutApi = ({ state, setState }: { state: Checkout; setState: any }): CheckoutApi => {
  const _submitPayment: getInputValues = async (
    data,
  ): Promise<{ referenceId: string; email: string } | undefined> => {
    try {
      const { customer, cost, payment, reservation_id } = data;

      const newReservation = await bookNewEvent({ ...customer, cost });

      setState(
        produce((draft) => {
          draft.customer = { ...state.customer, ...customer };
          draft.cost = cost;
          draft.reservation_id = reservation_id;
        }),
      );
      return { referenceId: newReservation.data.referenceId, email: newReservation.data.email };
    } catch (err) {
      console.log(err.response.data);
      console.error(err);
      setState(
        produce((draft) => {
          draft.error = true;
          draft.errorMessage = err.response.data[0].description;
        }),
      );
    }
  };
  return { state, _submitPayment };
};

const [CheckoutProvider, useCheckout] = createStore(checkoutApi, initialState());

export { CheckoutProvider, useCheckout };
