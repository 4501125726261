const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const monthOfYear = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

type IDate = Date | string | number | undefined;

const timeConversion = {
  getDate: (dateStr: IDate): string => {
    const dateObj = new Date(dateStr || '');
    const day = dayOfWeek[dateObj.getDay()];
    const date = dateObj.getUTCDate();
    const month = monthOfYear[dateObj.getUTCMonth()];

    return `${day}, ${month} ${date}`;
  },

  getTime: (dateStr: IDate): string => {
    const date = new Date(dateStr || '');
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    const actualMinute = minutes < 10 ? '0' + minutes : minutes;

    const strTime = `${hours}:${actualMinute} ${ampm}`;
    return strTime;
  },
};

export default timeConversion;
