/* eslint-disable camelcase */
import React, { ReactElement } from 'react';
import { debounce } from 'lodash';
import PropTypes, { InferProps } from 'prop-types';
import withHooks from 'Utils/withHooks.jsx';
import { useForm, Controller } from 'react-hook-form';
import { ILoading } from '_interfaces/Loading';
import { Checkout } from '_interfaces/Checkout/Checkout';
import { useCheckout } from '_stores/checkoutStore';
import { useEventStore } from '_stores/eventsAndStoresStore';
import { useStepper } from '_stores/stepperStore';
import Dropdown from '_Ui/Dropdown/dropown';
import { CheckoutApi, getInputValues, submitInfo } from '_interfaces/Checkout/CheckoutApi';
import { SyntheticOnClick } from '_interfaces/OnClick';
import Form from 'Components/Form/form';
import FieldInput from 'Components/FieldInput/fieldInput';
import MaskedFieldInput from 'Components/FieldInput/maskedFieldInput';
import OrderSummary from 'Components/OrderSummary/orderSummary';
import Payment from 'Components/Payment/payment';
import FloatingContainer from '_Ui/styledContainers/floatingContainer';
import stateOptons from 'Utils/_const/stateOptions';
import ParentContainer from '_Ui/styledContainers/parentContainer';
import Text from 'Components/Text/text';
import { useHistory } from 'react-router-dom';
import fontSizes from 'Utils/_const/fontSizes';
import withLoading from 'Containers/Loading/loading';
import Errors from '_Ui/Errors/errors';
import styles from './checkout.module.scss';
import transformData from './transformData';
import nameFields from '../../Utils/fields/nameFields';

const propTypes = {
  cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

type Props = InferProps<typeof propTypes> & {
  checkoutState: Checkout;
  _submitPayment: getInputValues;
  selectedEvent: { reservation_id: string; cost: number | string };
  reset(): void;
  setLoading: ILoading;
};

const Checkout: React.FC<Props> = React.memo(function Checkout({
  checkoutState,
  _submitPayment,
  selectedEvent,
  reset,
  setLoading,
}): ReactElement {
  const { register, handleSubmit, errors, control, watch } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { error, errorMessage } = checkoutState || {};
  const { cost, reservation_id } = selectedEvent || '0';
  const history = useHistory();

  const _onSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true);
      const { referenceId, email } =
        (await _submitPayment(transformData(data, reservation_id, cost))) || {};

      if (referenceId || email) {
        history.push('/order-confirmation', {
          referenceId,
          email,
        });
        reset();
      }
    } catch (err) {
      console.error(err);
      // handle error here;
    } finally {
      setLoading(false);
    }
  });

  const { firstName, lastName, email, phone, address, city, zipcode, state } = nameFields;

  return (
    <ParentContainer>
      <h1 className="text-center">
        <Text text="Checkout" bold={true} size={'1.8rem'} />
      </h1>
      <div className={styles.checkout}>
        <FloatingContainer>
          <OrderSummary cost={cost || 0} />
        </FloatingContainer>
        <Form id={'checkoutForm'} _onSubmit={_onSubmit} buttonText={'Pay and book training'}>
          <div className={styles.name}>
            <FieldInput
              label={'First name'}
              id={'checkoutFirstName'}
              name={firstName}
              arialabel={'first name'}
              register={register}
              errors={errors}
              constraints={{
                required: { value: true, message: 'Please enter first name' },
                shouldFocusError: true,
              }}
            />
            <FieldInput
              label={'Last name'}
              id={'checkoutLastName'}
              name={lastName}
              arialabel={'last name'}
              register={register}
              errors={errors}
              constraints={{
                required: { value: true, message: 'Please enter last name' },
                shouldFocusError: true,
              }}
            />
          </div>
          <FieldInput
            label={'Email'}
            id={'checkoutEmail'}
            name={email}
            arialabel={'email'}
            register={register}
            errors={errors}
            constraints={{
              required: 'Please enter email',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
              shouldFocusError: true,
            }}
          />
          <Controller
            control={control}
            defaultValue=""
            name={phone}
            rules={{
              required: { value: true, message: 'Please enter phone number' },
              // shouldFocusError: true,
              pattern: {
                value: /^(\([0-9]{3}\)\s*|[0-9]{3}\-)[0-9]{3}-[0-9]{4}$/,
                message: 'invalid phone number',
              },
            }}
            render={(formProps) => (
              <MaskedFieldInput
                label={'Phone'}
                id={'checkoutPhone'}
                arialabel={'phone'}
                register={register}
                mask={'(999) 999-9999'}
                errors={errors}
                {...formProps}
              />
            )}
          />
          <FieldInput
            label={'Address'}
            id={'checkoutAddress'}
            name={address}
            arialabel={'address'}
            register={register}
            errors={errors}
            constraints={{
              required: { value: true, message: 'Please enter address' },
              shouldFocusError: true,
            }}
          />
          <div className={styles.address}>
            <FieldInput
              label={'City'}
              id={'checkoutCity'}
              name={'city'}
              arialabel={city}
              register={register}
              errors={errors}
              constraints={{
                required: { value: true, message: 'Please enter your city' },
                shouldFocusError: true,
              }}
            />
            <Controller
              control={control}
              defaultValue=""
              name={zipcode}
              rules={{
                required: { value: true, message: 'Please enter a zipcode' },
              }}
              render={(formProps) => (
                <MaskedFieldInput
                  label={'Zip Code'}
                  id={'checkoutZipcodeInput'}
                  arialabel={'zip code input'}
                  mask="99999"
                  errors={errors}
                  {...formProps}
                />
              )}
            />
            <Dropdown
              label={'State'}
              id={'checkoutState'}
              options={stateOptons}
              errors={errors}
              name={state}
              constraints={{ required: { value: true, message: 'Please select a state' } }}
              register={register}
            />
          </div>
          <Payment control={control} errors={errors} />
          {error && <Errors message={errorMessage || 'An Error has occurred, please try again'} />}
        </Form>
      </div>
    </ParentContainer>
  );
});

Checkout.propTypes = propTypes;

const mapHooksToProps = () => {
  const { state, _submitPayment } = useCheckout();

  const eventState = useEventStore();
  const { reset } = useStepper();

  const { _id } = eventState.state.selectedReservation || {};
  const { cost } = eventState.state.selectedEvent || 0;

  return {
    checkoutState: state,
    selectedEvent: { cost, reservation_id: _id },
    _submitPayment,
    reset,
  };
};

const WithLoadingCheckout = withLoading({
  WrappedComponent: Checkout,
  loadingMessage: 'Processing Payment...',
});

export default withHooks(mapHooksToProps)(WithLoadingCheckout);
