import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import SelectedInfo from 'Components/selectedInfo/selectedInfo';
import { Reservation } from '_interfaces/Events/Reservation';
import { SyntheticOnClick } from '_interfaces/OnClick';
import Text from 'Components/Text/text';
import timeConversions from 'Utils/timeConversions';

const propTypes = {};

type Props = InferProps<typeof propTypes> & {
  reservation: Reservation;
  onClick(reservationId: string): void;
};

const SelectedReservation: React.FC<Props> = React.memo(function SelectedReservation({
  reservation,
  onClick,
}): ReactElement | null {
  const { startTime } = reservation || {};

  const formatedDate = timeConversions.getDate(startTime);
  const formattedStartTime = timeConversions.getTime(startTime);
  return reservation ? (
    <SelectedInfo
      selectedTitle={'Date and Time Selected'}
      title={formatedDate}
      info={formattedStartTime}
      icon={'graduation-cap'}
      onClick={onClick}
    />
  ) : null;
});

SelectedReservation.propTypes = propTypes;

export default SelectedReservation;
