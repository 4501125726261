import React, { ReactElement } from 'react';
import { MDBIcon } from 'mdbreact';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import Text from 'Components/Text/text';
import ActionContainer from '_Ui/styledContainers/actionContainer';
import { SyntheticOnClick } from '_interfaces/OnClick';
import styles from './selectedInfo.module.scss';

const propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectedTitle: PropTypes.string.isRequired,
};

type Props = InferProps<typeof propTypes> & { onClick: any };

const SelectedInfo: React.FC<Props> = React.memo(function SelectedInfo({
  title,
  info,
  icon,
  selectedTitle,
  onClick,
  children,
}): ReactElement {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <MDBIcon icon={icon} className={classNames('text-white')} style={{ fontSize: '1.3rem' }} />
      </div>
      <div className={styles.info}>
        <Text text={selectedTitle} bold={true} size={'16px'} />
        <div className={'info'}>
          <Text text={title} bold={true} size={'14px'} />
        </div>
        <div className={styles.info__sub}>
          <Text text={info} size={'14px'} />
          {children}
        </div>
      </div>
      <ActionContainer text={'Edit'} onClick={onClick} />
    </div>
  );
});

SelectedInfo.propTypes = propTypes;

export default SelectedInfo;
