import React, { memo, ReactElement } from 'react';
import styled from 'styled-components';
import PropTypes, { InferProps } from 'prop-types';

const propTypes = {};

type Props = InferProps<typeof propTypes>;

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 25px;
  padding: 15px;
  height: 100%;
  max-height: 500px;
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  background-color: #fafafa;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px -7px rgba(0, 0, 0, 0.2);
`;

const FloatingContainer: React.FC<Props> = function FloatingContainer({ children }): ReactElement {
  return <Wrapper>{children}</Wrapper>;
};

FloatingContainer.propTypes = propTypes;

export default FloatingContainer;
