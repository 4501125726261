import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import withHooks from 'Utils/withHooks.jsx';
import { useStepper } from '_stores/stepperStore';
import services from 'Utils/_const/services';
import LocationPicker from 'Containers/locationPicker/locationPicker';
import SelectedSummary from 'Containers/SelectedSummary/selectedSummary';
import VisibleCalendar from 'Containers/Events/visibleCalendar';
import { VisibleEvents } from 'Containers/Events/visibleEvents';
import VisibleReservations from 'Containers/Reservations/visibleReservations';
import Text from 'Components/Text/text';
import { MDBContainer } from 'mdbreact';
import Checkout from 'Containers/Checkout/checkout';
import fontSizes from 'Utils/_const/fontSizes';
import Selections from '../Selections/selections';

const propTypes = {
  step: PropTypes.number.isRequired,
  service: PropTypes.string.isRequired,
  trainingType: PropTypes.string.isRequired,
  storeSelected: PropTypes.bool,
  eventSelected: PropTypes.bool,
  reservationSelected: PropTypes.bool,
};

type Props = InferProps<typeof propTypes>;

const Stepper: React.FC<Props> = React.memo(function Stepper({
  children,
  step,
  service,
  trainingType,
  storeSelected,
  eventSelected,
  reservationSelected,
}): ReactElement {
  return (
    <MDBContainer>
      {reservationSelected ? null : <SelectedSummary />}
      {storeSelected ? null : <Selections />}
      {/* Renders inside modal */}
      {step > 0 && !storeSelected && !eventSelected && !reservationSelected && <LocationPicker />}
      {storeSelected && !eventSelected ? (
        <>
          <VisibleCalendar />
          <VisibleEvents />
        </>
      ) : null}
      {reservationSelected || step === 0 ? null : <VisibleReservations />}
      {storeSelected && eventSelected && reservationSelected && <Checkout />}
      {children}
    </MDBContainer>
  );
});

Stepper.propTypes = propTypes;

const mapHooksToProps = () => {
  const {
    state: { step, service, trainingType, storeSelected, eventSelected, reservationSelected },
  } = useStepper();
  return { step, service, trainingType, storeSelected, eventSelected, reservationSelected };
};

export default withHooks(mapHooksToProps)(Stepper);
