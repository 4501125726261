import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';
import Button from '_Ui/Button/button';

const propTypes = {
  _onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

type Props = InferProps<typeof propTypes> & {
  _onSubmit(event: React.FormEvent<HTMLFormElement>): void;
};

const FormWrapper = styled.form`
  display: grid;
  grid-template-row: 15px;
`;

const Form: React.FC<Props> = React.memo(function Form({
  _onSubmit,
  children,
  id,
  buttonText,
}): ReactElement {
  return (
    <FormWrapper onSubmit={_onSubmit} id={id}>
      {children}
      <Button text={buttonText} submit={true} color={'primary'} />
    </FormWrapper>
  );
});

Form.propTypes = propTypes;

export default Form;
