import React, { ReactElement, useState } from 'react';
import { ReactComponentLike } from 'prop-types';
import { TMessage, Toast } from '_interfaces/Toast';
import { MDBNotification, MDBContainer } from 'mdbreact';
import ParentContainer from '_Ui/styledContainers/parentContainer';
import timeConversions from 'Utils/timeConversions';

type Notifications = { message: TMessage };

/**
 * Please refer to icon and iconColor below:
 * https://mdbootstrap.com/docs/react/content/icons-list/
 * https://mdbootstrap.com/docs/react/css/colors/
 */

interface Props {
  WrappedComponent: ReactComponentLike;
  title: string;
  iconColor?: string;
  icon?: string;
}

export default function withToast({ WrappedComponent, icon, title, iconColor }: Props): any {
  function WithToast(props: any): ReactElement {
    const [notifications, setState] = useState<Array<Notifications>>([]);

    const newToast: Toast = (message) => {
      const newNotifications = [...notifications, { message }];
      setState(newNotifications);
    };

    return (
      <ParentContainer>
        {notifications && (
          <MDBContainer
            style={{
              width: 'auto',
              position: 'fixed',
              top: '10px',
              right: '10px',
              zIndex: 9999,
            }}>
            {notifications.map(({ message }, i) => (
              <MDBNotification
                key={i}
                show
                fade
                icon={icon || 'bell'}
                iconClassName={iconColor || 'text-warning'}
                title={title}
                message={message}
                text={timeConversions.getTime(new Date())}
              />
            ))}
          </MDBContainer>
        )}

        <WrappedComponent {...props} newToast={newToast} />
      </ParentContainer>
    );
  }
  return WithToast;
}
