import React, { ReactElement, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact';
import Text from 'Components/Text/text';

const propTypes = {
  header: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  button: PropTypes.bool,
};

interface Children {
  toggle?(): void;
}

type Props = InferProps<typeof propTypes> & Children;

const Modal: React.FC<Props> = React.memo(function Modal({
  header,
  children,
  isOpen,
  toggle,
  button,
}): ReactElement {
  return (
    <MDBContainer>
      <MDBModal
        isOpen={isOpen}
        toggle={toggle}
        inline={false}
        overflowScroll
        noClickableBodyWithoutBackdrop={true}>
        <MDBModalHeader toggle={toggle}>
          <Text text={header} bold={true} />
        </MDBModalHeader>
        <MDBModalBody>{children}</MDBModalBody>
        <MDBModalFooter>
          {button && (
            <MDBBtn outline color="blue" onClick={toggle}>
              Close
            </MDBBtn>
          )}
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
});

Modal.defaultProps = { button: true };

export default Modal;
