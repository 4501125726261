import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { SyntheticOnClick } from '_interfaces/OnClick';
import { Stores } from '_interfaces/Stores/Stores';
import Text from 'Components/Text/text';
import Button from '_Ui/Button/button';
import ActionContainer from '_Ui/styledContainers/actionContainer';
import styles from './stores.module.scss';
import StoreInfo from './storeInfo';

const propTypes = {};

type Props = InferProps<typeof propTypes> & { onClick: SyntheticOnClick; store: Stores };

const Store: React.FC<Props> = React.memo(function Store({ store, onClick }): ReactElement {
  const { _id, name, address, distance } = store;
  return (
    <div className={styles.storeWrapper}>
      <StoreInfo name={name} address={address} />
      <ActionContainer text={'Select'} _id={_id} onClick={onClick}>
        <Text text={`${distance} mi`} size={'14px'} className="mr-1" />
      </ActionContainer>
    </div>
  );
});

Store.propTypes = propTypes;

export default Store;
