import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { InterfaceEvents } from '_interfaces/Events/Events';
import Text from 'Components/Text/text';
import Button from '_Ui/Button/button';
import InfoContainer from '_Ui/styledContainers/itemContainer';
import styles from './events.module.scss';

const propTypes = {};

type Props = InferProps<typeof propTypes> & {
  event: InterfaceEvents;
  onClick(eventId: string): void;
};

const Event: React.FC<Props> = React.memo(function Event({ onClick, event }): ReactElement {
  const { _id, name, audience, about, cost, teaches, duration } = event;
  const detailFontSize = '14px';
  const bigFontSize = '16px';
  return (
    <InfoContainer maxWidth={'700px'} gridTemplateCol={'77% 20%'}>
      <div className={styles.info}>
        <div className={styles.header}>
          <Text text={name} size={bigFontSize} bold={true} transform={'capitalize'} />
        </div>
        <div className={styles.about}>
          <Text
            text={about + ' - ' + duration + ' - ' + audience}
            size={detailFontSize}
            transform={'capitalize'}
          />
        </div>
        <div className={styles.teaches}>
          <Text text={teaches} size={detailFontSize} className={'grey-text'} />
        </div>
      </div>
      <div className={styles.action}>
        <Text text={`$${cost}`} bold={true} size={bigFontSize} className={'mr-1'} />
        <Button small={true} onClick={() => onClick(_id)} color={'primary'} text={'Select'} />
      </div>
    </InfoContainer>
  );
});

Event.propTypes = propTypes;

export default Event;
