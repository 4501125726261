import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ItemContainer from '_Ui/styledContainers/itemContainer';
import ActionContainer from '_Ui/styledContainers/actionContainer';
import { Reservation } from '_interfaces/Events/Reservation';
import Text from 'Components/Text/text';
import timeConversions from 'Utils/timeConversions';
import styles from './reservation.module.scss';

const propTypes = {};

type Props = InferProps<typeof propTypes> & { reservation: Reservation; onClick?: any };

const ReservationInfo: React.FC<Props> = React.memo(function ReservationInfo({
  onClick,
  reservation,
}): ReactElement {
  const { startTime, endTime, slots } = reservation;

  const formatedDate = timeConversions.getDate(startTime);
  const formattedStartTime = timeConversions.getTime(startTime);
  const formattedEndTime = timeConversions.getTime(endTime);
  const fontSize = '14px';

  return (
    <ItemContainer maxWidth={'470px'} gridTemplateCol={'60% 36%'}>
      <div className={styles.info}>
        <Text text={'Date: ' + formatedDate} />
        <Text
          text={'Start time: ' + formattedStartTime}
          size={fontSize}
          className={styles['Avenir-Book']}
        />
        <Text
          text={'End time: ' + formattedEndTime}
          size={fontSize}
          className={styles['Avenir-Book']}
        />
      </div>
      <ActionContainer text={'Select'} onClick={onClick}>
        <Text text={slots + ' Available'} bold={true} appearance={'success'} className={'mr-1'} />
      </ActionContainer>
    </ItemContainer>
  );
});

ReservationInfo.propTypes = propTypes;

export default ReservationInfo;
