import { MouseEvent } from 'react';
import withHooks from 'Utils/withHooks.jsx';
import { useStepper } from '_stores/stepperStore';
import { useEventStore } from '_stores/eventsAndStoresStore';
import ServiceSelection from 'Components/serviceSelection/serviceSelection';
import services from 'Utils/_const/services';
import { SyntheticOnClick } from '_interfaces/OnClick';

const trainingSelectionProps = {
  header: 'What type of training class are you interested in?',
  text1: 'In-Store',
  text2: 'Virtual',
  serviceName1: 'inStore',
  serviceName2: 'virtual',
};

const mapHooksToProps = () => {
  const {
    pickTraining,
    next,
    reset,
    state: { trainingType, step },
  } = useStepper();

  const { filterStoreByCategory } = useEventStore();

  return {
    ...trainingSelectionProps,
    next,
    reset,
    step,
    trainingType,
    service: services.dogTraining,
    onClick: (e: MouseEvent<HTMLElement>): void => {
      pickTraining(e);
      filterStoreByCategory(e);
    },
  };
};

export default withHooks(mapHooksToProps)(ServiceSelection);
