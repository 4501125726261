import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import MaskedFieldInput from 'Components/FieldInput/maskedFieldInput';
import paymentFields from 'Utils/fields/paymentFields';
import styles from './payment.module.scss';

type Props = { control: Control; errors: FieldErrors };

const Payment: React.FC<Props> = React.memo(function Payment({ control, errors }): ReactElement {
  const { cardNumber, cardExp, cvv } = paymentFields;
  return (
    <div className={styles.payment}>
      <Controller
        control={control}
        defaultValue=""
        name={cardNumber}
        rules={{
          required: { value: true, message: 'Missing card number' },
        }}
        render={(formProps) => (
          <MaskedFieldInput
            label={'Card Number'}
            id={'checkoutCardNumber'}
            arialabel={'card number'}
            mask="9999 9999 9999 9999"
            errors={errors}
            {...formProps}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        name={cardExp}
        rules={{
          required: { value: true, message: 'Missing card expiration' },
        }}
        render={(formProps) => (
          <MaskedFieldInput
            label={'Exp'}
            id={'checkoutCardExp'}
            arialabel={'card expiration'}
            mask="99/99"
            placeholder={'mm/yy'}
            errors={errors}
            {...formProps}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        name={cvv}
        rules={{
          required: { value: true, message: 'Missing cvv' },
        }}
        render={(formProps) => (
          <MaskedFieldInput
            label={'CVV'}
            placeholder={'CVC'}
            id={'checkoutCardCVV'}
            arialabel={'card cvv'}
            mask="999"
            errors={errors}
            {...formProps}
          />
        )}
      />
    </div>
  );
});

export default Payment;
