export default {
  REFERENCE_ID: 'referenceId',
  REDEMPTION: 'redemption',
  COST: 'cost',
  RESERVATION_STATUS: 'reservationStatus',
  DATE: 'date',
  TIME: 'time',
  HANDLE: 'cancel',
  NAME: 'name',
  LOCATION_ADDRESS: 'locationAddress',
};

/** * {
 *  'referenceId': 1,
 *  'redemption',
 *  'cost',
 *  'reservationStatus',
 *  'time',
 *  'handler: <MDBBtn color="purple" size="sm">Button</MDBBtn>
 * }, */
