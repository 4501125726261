import React, { ReactElement, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import withHooks from 'Utils/withHooks.jsx';
import { useEventStore } from '_stores/eventsAndStoresStore';
import { useStepper } from '_stores/stepperStore';
import PropTypes, { InferProps } from 'prop-types';
import MaskedFieldInput from 'Components/FieldInput/maskedFieldInput';
import Form from 'Components/Form/form';
import Modal from '_Ui/Modal/modal';
import { VisibleStore } from 'Containers/Stores/visibleStores';

const Line = styled.div`
  display: block;
  border: solid 1px #979797;
  height: 1px;
  margin: 25px auto 20px;
  text-align: center;
`;

const propTypes = {
  filterStore: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

type Values = { zipcode: string | any };
type Props = InferProps<typeof propTypes> & Values;

const LocationPicker: React.FC<Props> = React.memo(function LocationPicker({
  filterStore,
  zipcode,
  step,
}): ReactElement {
  const [isOpen, toggle] = useState(true);
  const { handleSubmit, errors, control, reset } = useForm();
  const constraints = {
    required: { value: true, message: 'Please enter a zipcode' },
    minLength: {
      value: 5,
      message: 'Zipcode minimum length is 5',
    },
    shouldFocusError: true,
  };

  useEffect(() => {
    if (!isOpen) toggle(true);
  }, [step]);

  return (
    <Modal header={'Choose a location'} isOpen={isOpen} toggle={() => toggle(!isOpen)}>
      <Form
        _onSubmit={handleSubmit(filterStore)}
        id={'locationPicker'}
        buttonText={'find training location'}>
        <Controller
          control={control}
          defaultValue={zipcode || ''}
          name={'zipcode'}
          rules={constraints}
          render={(formProps) => (
            <MaskedFieldInput
              label={'Zip Code'}
              id={'zipcodeInput'}
              arialabel={'zip code input'}
              mask="99999"
              errors={errors}
              placeholder={zipcode || 'Enter Zip'}
              {...formProps}
            />
          )}
        />
      </Form>
      <Line />
      {zipcode !== '' && zipcode && <VisibleStore toggle={toggle} />}
    </Modal>
  );
});

const mapHooksToProps = () => {
  const {
    filterStore,
    state: { stores, zipcode },
  } = useEventStore() || {};
  const {
    state: { step },
  } = useStepper();
  return { filterStore, stores, zipcode, step };
};

LocationPicker.propTypes = propTypes;
export default withHooks(mapHooksToProps)(LocationPicker);
