import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

const Div = styled.div`
  display: grid;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  grid-template-columns: ${({ theme }) => theme.gridTemplateCol};
  column-gap: 20px;
  padding: 10px 15px;
  border: solid 1px #000;
  margin: auto;
`;

const propTypes = {
  gridTemplateCol: PropTypes.string.isRequired,
  maxWidth: PropTypes.string.isRequired,
};

type Props = InferProps<typeof propTypes>;

const ItemContainer: React.FC<Props> = function ItemContainer({
  gridTemplateCol,
  maxWidth,
  children,
}) {
  const theme = {
    gridTemplateCol,
    maxWidth,
  };
  return (
    <ThemeProvider theme={theme}>
      <Div>{children}</Div>
    </ThemeProvider>
  );
};

ItemContainer.propTypes = propTypes;

export default ItemContainer;
