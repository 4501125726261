import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useLocation } from 'react-router-dom';
import ParentContainer from '_Ui/styledContainers/parentContainer';
import Text from 'Components/Text/text';

const propTypes = {
  referenceId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

type Props = InferProps<typeof propTypes>;

function OrderDetails({ referenceId, email }: Props): ReactElement {
  return (
    <ParentContainer>
      <Text
        text={`Thank you for booking an event with us. We sent an email confirmation to: ${email}`}
      />
      <Text text={`This is your order confirmation number: ${referenceId}`} />
    </ParentContainer>
  );
}

const OrderConfirmation = (): ReactElement => {
  const location = useLocation();

  const {
    state: { referenceId, email },
  } = location || {};

  return <OrderDetails referenceId={referenceId} email={email} />;
};
OrderDetails.propTypes = propTypes;
OrderDetails.defaultProps = { email: 'n/a', referenceId: 'n/a' };

export default OrderConfirmation;
