import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { FieldErrors } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Errors from '_Ui/Errors/errors';
import styles from './fieldInput.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  arialabel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  constraints: PropTypes.object,
  type: PropTypes.string,
  required: PropTypes.bool,
  _onChange: PropTypes.func,
};

interface Errors {
  mask: string;
  errors: FieldErrors;
}

type Props = InferProps<typeof propTypes> & Errors & React.HTMLProps<HTMLInputElement>;

const MaskedFieldInput = React.forwardRef<InputMask, Props>(function MaskedFieldInput(
  { label, id, type, arialabel, name, placeholder, mask, required, errors, onChange, onBlur },
  ref,
): ReactElement {
  return (
    <div className="md-form">
      <InputMask
        type={type || 'text'}
        aria-label={label}
        id={id}
        className={classNames('form-control active', styles.input)}
        aria-disabled="false"
        placeholder={placeholder}
        mask={mask}
        required={required}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
      />
      <label
        aria-labelledby={arialabel}
        className={classNames('active', styles.label)}
        htmlFor={id}>
        {label}
      </label>
      {errors && errors[name] && <Errors message={errors[name].message} />}
    </div>
  );
});

MaskedFieldInput.propTypes = propTypes;
export default MaskedFieldInput;
