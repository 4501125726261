import React, { ReactElement } from 'react';
import { Stores } from '_interfaces/Stores/Stores';
import PropTypes, { InferProps } from 'prop-types';
import SelectedInfo from 'Components/selectedInfo/selectedInfo';

const propTypes = { onClick: PropTypes.func.isRequired };

type Props = InferProps<typeof propTypes> & { store: Stores };

const SelectedStore: React.FC<Props> = React.memo(function SelectedStore({
  store,
  onClick,
}): ReactElement | null {
  const { name, address } = store || 'placeholder';
  return store ? (
    <SelectedInfo
      selectedTitle={'Location Selected'}
      title={name}
      info={address}
      icon={'map-marker-alt'}
      onClick={onClick}
    />
  ) : null;
});

SelectedStore.propTypes = propTypes;

export default SelectedStore;
