import React, { ReactElement } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';

/**
 * What do I want this component to do?
 * @param param
 */

const propTypes = {
  appearance: PropTypes.oneOf(['success', 'warn', 'danger', 'code', 'info', '']),
  bold: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  transform: PropTypes.string,
  className: PropTypes.string,
  // Uses hex code
  color: PropTypes.string,
  // font-size
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  center: PropTypes.bool,
};

type Props = InferProps<typeof propTypes>;

const StyledElement = styled.span`
  color: ${({ theme }) => theme.color};
  font-size: ${({ theme }) => theme.size};
`;

const textComp: React.FC<Props> = React.memo(function Text({
  appearance,
  bold,
  text,
  className,
  color,
  transform,
  size,
  center,
}): ReactElement {
  const theme = {
    color,
    size,
  };
  return (
    <ThemeProvider theme={theme}>
      <StyledElement
        className={classNames(
          className,
          bold ? 'font-weight-bold' : '',
          appearance ? `text-${appearance}` : '',
          transform ? `text-${transform}` : '',
          center ? 'text-center' : '',
        )}>
        {text}
      </StyledElement>
    </ThemeProvider>
  );
});

textComp.propTypes = propTypes;
textComp.defaultProps = {
  size: '1em',
  appearance: '',
};
export default textComp;
