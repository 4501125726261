import React, { ReactElement } from 'react';
import { MDBBtn } from 'mdbreact';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { SyntheticOnClick } from '_interfaces/OnClick';
import styles from './button.module.scss';

const propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  submit: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string,
  dataInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

interface Btn {
  onClick?: SyntheticOnClick;
  type?: 'button' | 'reset' | 'submit' | undefined;
}

type Props = InferProps<typeof propTypes> & Btn;

const Button: React.FC<Props> = React.memo(function Button({
  className,
  color,
  text,
  small,
  submit,
  dataInfo,
  onClick,
}): ReactElement {
  const primary = '#dd1500 !important';
  const secondary = '#fff';

  return (
    <MDBBtn
      className={classNames(className, styles.cfButton, color === 'primary' ? 'prim' : 'second')}
      id="cfButton"
      onClick={onClick}
      size={small ? 'sm' : undefined}
      type={submit ? 'submit' : 'button'}
      data-target={dataInfo}>
      {text}
    </MDBBtn>
  );
});

Button.propTypes = propTypes;
Button.defaultProps = {
  color: 'primary',
};

export default Button;
