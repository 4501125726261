import React, { ReactElement, HTMLAttributes } from 'react';
import { MDBIcon } from 'mdbreact';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import Text from 'Components/Text/text';
import styles from './textBox.module.scss';

const propsType = {
  text: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

interface Actions {
  onClick(e: React.MouseEvent<HTMLElement>): void;
}

type Props = InferProps<typeof propsType> & Actions;

const TextBox: React.FC<Props> = React.memo(function TextBox({
  text,
  active,
  onClick,
  name,
}): ReactElement {
  return (
    <div
      className={classNames(styles.box, active ? styles.box__active : '')}
      data-name={name}
      onClick={(e) => onClick(e)}>
      <MDBIcon
        far
        icon="check-circle"
        className={classNames('green-text', styles.icon, active ? 'd-block' : 'd-none')}
      />
      <Text text={text} bold={true} size="20px" />
    </div>
  );
});

TextBox.propTypes = propsType;

export default TextBox;
