import { Stores } from '_interfaces/Stores/Stores';
import getGeolocation from '_requests/getGeolocation';
import haverSineDistance from './haversineDistance';

interface UpdatedStores extends Stores {
  distance: string;
}

export default async (
  stores: Array<Stores> | Stores[],
  userZipcode: string,
): Promise<Array<UpdatedStores> | undefined> => {
  try {
    const replaced = userZipcode.replace(/_/g, '');
    const userLocation = await getGeolocation(replaced);
    const { lat, lng } = userLocation.data.results[0].geometry.location || {};

    return stores
      .map((store) => {
        return {
          ...store,
          distance: haverSineDistance({ lat, lng }, { lat: store.lat, lng: store.lng }),
        };
      })
      .sort((a, b) => parseInt(a.distance, 10) - parseInt(b.distance, 10));
  } catch (err) {
    console.error(err);
    throw err;
    // handle error here;
  }
};
