import produce from 'immer';
import { Stepper } from '_interfaces/Stepper';
import { SyntheticOnClick, DivElementOnClick } from '_interfaces/OnClick';
import _stepper from 'Utils/_const/stepper';
import createStore from './createStore';

/**
 * step: string;
 * service: string;
 */
interface Props {
  state: Stepper;
  setState: any;
}

// type OnClick = (event: React.MouseEvent<HTMLDivElement>) => void;

interface ReturnApi {
  state: Stepper;
  pickTraining: DivElementOnClick;
  pickService: DivElementOnClick;
  next: SyntheticOnClick;
  reset: SyntheticOnClick;
  select(seleted: string): void;
  edit(selected: string): void;
}

const initialState = {
  service: '',
  trainingType: '',
  step: 0,
  storeSelected: false,
  eventSelected: false,
  reservationSelected: false,
  loading: false,
};

const stepperApi = ({ state, setState }: Props): ReturnApi => {
  const pickService: DivElementOnClick = (event) => {
    const { name } = event.currentTarget.dataset;
    setState(
      produce((draft) => {
        draft.service = name;
      }),
    );
  };
  const pickTraining: DivElementOnClick = (event) => {
    const { name } = event.currentTarget.dataset;
    setState(
      produce((draft) => {
        draft.trainingType = name;
      }),
    );
  };

  // once service and training type are selected..., onClick would take the consumer to sectio
  // to enter the address

  const next: SyntheticOnClick = (event) => {
    event.preventDefault();
    if (state.service !== '' || state.trainingType !== '') {
      setState(
        produce((draft) => {
          draft.step = state.step + 1;
        }),
      );
    }
  };

  /**
   *
   * @param selected
   * To select store, event, or reservation
   */
  const select = (selected: string): void => {
    setState(
      produce((draft) => {
        draft[selected] = true;
        draft.loading = true;
      }),
    );
  };

  const edit = (selected: string): void => {
    if (selected === _stepper.storeSelected) {
      setState(
        produce((draft) => {
          draft.eventSelected = false;
          draft.storeSelected = false;
          draft.reservationSelected = false;
        }),
      );
    } else if (selected === _stepper.eventSelected) {
      setState(
        produce((draft) => {
          draft.eventSelected = false;
          draft.reservationSelected = false;
        }),
      );
    } else {
      setState(
        produce((draft) => {
          draft[selected] = false;
        }),
      );
    }
  };

  const reset = (): void => {
    window.location.reload();
  };

  return { state, pickTraining, pickService, next, reset, select, edit };
};

const [StepperProvider, useStepper] = createStore(stepperApi, initialState);

export { StepperProvider, useStepper };
