// import
import withHooks from 'Utils/withHooks.jsx';
import { useEventStore } from '_stores/eventsAndStoresStore';
import SelectedReservation from 'Components/reservationInfo/selectedReservation';
import { useStepper } from '_stores/stepperStore';
import _stepper from 'Utils/_const/stepper';

const mapHooksToProps = () => {
  const {
    resetStateOnEdit,
    state: { selectedReservation },
  } = useEventStore();
  const { edit } = useStepper();
  return {
    reservation: selectedReservation,
    onClick: () => {
      edit(_stepper.reservationSelected);
    },
  };
};

export default withHooks(mapHooksToProps)(SelectedReservation);
