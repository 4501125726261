import withHooks from 'Utils/withHooks.jsx';
import { useStepper } from '_stores/stepperStore';
import { useEventStore } from '_stores/eventsAndStoresStore';
import ServiceSelection from 'Components/serviceSelection/serviceSelection';
import services from 'Utils/_const/services';

const serviceSelectionProps = {
  header: 'What are you interested in?',
  text1: 'Breath-savers',
  text2: 'Dog Training',
  serviceName1: services.breathSavers,
  serviceName2: services.dogTraining,
};

const mapHooksToProps = () => {
  const {
    pickService,
    next,
    reset,
    state: { service, step },
  } = useStepper();

  const { filterStoreByCategory } = useEventStore();

  return {
    ...serviceSelectionProps,
    next,
    reset,
    service,
    step,
    onClick: (trainingType: string): void => {
      pickService(trainingType);
      filterStoreByCategory(trainingType);
    },
  };
};

export default withHooks(mapHooksToProps)(ServiceSelection);
