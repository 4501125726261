import React, { ReactElement, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import TextBox from 'Components/TextBox/textBox';
import Text from 'Components/Text/text';
import Button from '_Ui/Button/button';
import { SyntheticOnClick, DivElementOnClick } from '_interfaces/OnClick';
import services from 'Utils/_const/services';
import styles from './serviceSelection.module.scss';

const propTypes = {
  header: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  serviceName1: PropTypes.string.isRequired,
  serviceName2: PropTypes.string.isRequired,
  service: PropTypes.string,
  trainingType: PropTypes.string,
};

interface Actions {
  onClick: DivElementOnClick;
  next: SyntheticOnClick;
  reset: SyntheticOnClick;
}

type Props = InferProps<typeof propTypes> & Actions;

const ServiceSelection: React.FC<Props> = React.memo(function ServiceSelection({
  onClick,
  next,
  reset,
  service,
  header,
  text1,
  text2,
  serviceName1,
  serviceName2,
  trainingType,
}: Props): ReactElement {
  const [selected, select] = useState(false);

  return (
    <div className={styles.parentWrapper}>
      <h2 className="text-center my-5">
        <Text text={header} bold={true} size="20px" center={true} />
      </h2>
      <div className={classNames(styles.wrapper)}>
        <TextBox
          onClick={(event) => {
            console.log({ event });
            onClick(event);
            select(true);
          }}
          text={text1}
          active={service === serviceName1 || trainingType === serviceName1}
          name={serviceName1}
        />
        <TextBox
          onClick={(event) => {
            onClick(event);
            select(true);
          }}
          text={text2}
          active={service === serviceName2 || trainingType === serviceName2}
          name={serviceName2}
        />
      </div>
      <div className={styles.btnWrapper}>
        {service === services.dogTraining && (
          <Button className="mx-auto mt-sm-5" color="secondary" text="Start Over" onClick={reset} />
        )}
        {selected && (
          <Button className="mx-auto mt-sm-5" color="primary" text="continue" onClick={next} />
        )}
      </div>
    </div>
  );
});

ServiceSelection.propTypes = propTypes;

export default ServiceSelection;
