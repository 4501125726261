import { InterfaceEvents } from '_interfaces/Events/Events';

interface SetInitialEvent {
  filteredEvents: Array<InterfaceEvents>;
  nextEventDate: Date;
}

const setInitialEvent = (
  events: Array<InterfaceEvents>,
  categoryStr: string,
  eventAttendanceMode: string,
): SetInitialEvent => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const filteredEvents = events
    .reduce((acc: Array<InterfaceEvents>, event: InterfaceEvents): Array<InterfaceEvents> => {
      const { startDate } = event;
      const date = new Date(startDate);
      date.setHours(0, 0, 0, 0);
      if (date >= today) {
        acc.push(event);
      }
      return acc;
    }, [])
    .filter((event) => {
      if (categoryStr === 'breathSavers') {
        return event.category.includes(categoryStr);
      }
      // else if (event.category === 'dogTraining' && event.eventAttendanceMode === categoryStr) {
      //   console.log({ categoryStr });
      //   return event.eventAttendanceMode.includes(categoryStr);
      // }
      return event.eventAttendanceMode.includes(categoryStr);
    });

  if (filteredEvents.length < 1) return { filteredEvents: [], nextEventDate: today };
  // First item in Array will always be the closest date
  const nextEventDate = new Date(filteredEvents[0].startDate);

  return { filteredEvents, nextEventDate };
};

export default setInitialEvent;
