/* eslint-disable camelcase */
import { Stores } from '_interfaces/Stores/Stores';
import { InterfaceEvents } from '_interfaces/Events/Events';
import { uniq } from 'lodash';

/**
 * Filter out stores with dogTraining and if virtual or inStore
 * @param trainingType
 * @param stores
 */

const filterStoreByCategory = (
  eventType: string | undefined,
  stores: Array<Stores>,
): Array<Stores> => {
  if (stores.length === 0) return [];
  const filteredCategory = stores.reduce((acc: Array<Stores>, store: Stores): Array<Stores> => {
    const { paid_events } = store;
    paid_events.forEach((paidEvent) => {
      const { category, eventAttendanceMode } = paidEvent;
      if (eventType === category) {
        acc.push(store);
      } else if (eventAttendanceMode === eventType && category === 'dogTraining') {
        acc.push(store);
      }
    });

    return acc;
  }, []);

  const uniqueStores = uniq(filteredCategory);
  console.log({ uniqueStores });

  return uniqueStores;
};

export default filterStoreByCategory;
