import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import SelectedInfo from 'Components/selectedInfo/selectedInfo';
import { InterfaceEvents } from '_interfaces/Events/Events';
import Text from 'Components/Text/text';
import timeConversions from 'Utils/timeConversions';
// TODO: Create date converter helper function
// Moment JS not working as expected

const propTypes = { onClick: PropTypes.func.isRequired };

type Props = InferProps<typeof propTypes> & { event: InterfaceEvents };

const SelectedEvent: React.FC<Props> = React.memo(function SelectedEvent({
  event,
  onClick,
}): ReactElement | null {
  const { name, audience, cost, startDate } = event || '';

  const formatedDate = timeConversions.getDate(startDate);

  return event ? (
    <SelectedInfo
      selectedTitle={'Event Selected'}
      title={name + ' - ' + audience}
      info={'Cost: $' + cost}
      icon={'calendar-check'}
      onClick={onClick}>
      <Text text={'Start date: ' + formatedDate} className={'d-block'} />
    </SelectedInfo>
  ) : null;
});

SelectedEvent.propTypes = propTypes;

export default SelectedEvent;
