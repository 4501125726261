import React, { ReactElement } from 'react';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
  MDBContainer,
  MDBLink,
} from 'mdbreact';
import Text from 'Components/Text/text';
import styles from './navBar.module.scss';

export default function NavBar(): ReactElement {
  const specialCaseNavbarStyles = {
    WebkitBoxOrient: 'horizontal',
    flexDirection: 'row',
  };

  return (
    <div className={styles.primColor}>
      <MDBNavbar double expand="md">
        <MDBContainer>
          <MDBNavbarNav left>
            <MDBNavItem
              className="d-none d-md-inline text-white text-uppercase font-weight-bold"
              style={{ paddingTop: 5 }}>
              <MDBLink to="/">centinela feed and pet supplies</MDBLink>
            </MDBNavItem>
          </MDBNavbarNav>
          <MDBNavbarNav right style={specialCaseNavbarStyles}>
            <MDBNavItem active>
              <a className="d-block mt-2" href="https://centinelafeed.com/products/shop-all/">
                <MDBIcon icon="envelope" className="d-inline-inline" /> <Text text="Shop" />
              </a>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to="/events">
                <MDBIcon far icon="comments" className="d-inline-inline" /> <Text text="Events" />
              </MDBNavLink>
            </MDBNavItem>
            {/* <MDBNavItem>
              <MDBNavLink to="/booking">
                <MDBIcon icon="address-book" className="d-inline-inline" />
                <Text text="Booking" />
              </MDBNavLink>
            </MDBNavItem> */}
            <MDBNavItem>
              <MDBNavLink to="/check-booked-reservations">
                <MDBIcon icon="user" className="d-inline-inline" /> <Text text="See Registration" />
              </MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
}
