import React, { useState, useEffect } from 'react';
import { MDBCard, MDBCardHeader, MDBCardBody, MDBDataTable } from 'mdbreact';
import PropTypes, { InferProps } from 'prop-types';

const propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  btn: PropTypes.bool,
  loadingData: PropTypes.bool.isRequired,
};

type Props = InferProps<typeof propTypes>;

/**
 *
 * @example
 * data to transform
 * "time": "2021-01-30T23:30:00.000Z",
 * "referenceId": "Klxy_s6",
 * "reservationStatus": "good",
 * "customerName": "Tim Tran",
 * "cost": 4500,
 * "redemption": false,
 * "paymentId": "ET195071",
 * "paymentTag": "4615131781",
 * "paymentRef": 1
 *
 * columns example:[
 *  {
 *    label: 'Ref Id',
 *    field: 'refId',
 *  }
 * ]
 *
 * Rows Example:
 * {
 *  'referenceId': 1,
 *  'redemption',
 *  'cost',
 *  'reservationStatus',
 *  'time',
 *  'handler: <MDBBtn color="purple" size="sm">Button</MDBBtn>
 * },
 *
 */

const DataTable: React.FC<Props> = React.memo(function DataTable({
  columns,
  data,
  btn,
  loadingData,
}) {
  const [dataVals, setData] = useState<{ columns: Array<any>; rows: Array<any> }>({
    columns: [],
    rows: [],
  });

  useEffect(() => {
    const dataInfo = { columns, rows: data };
    setData(dataInfo);
    return () => {
      setData({ columns: [], rows: [] });
    };
  }, [columns, data]);
  return (
    <MDBCard>
      <MDBCardHeader
        tag="h1"
        className="text-center white-text font-weight-bold text-uppercase py-4"
        style={{ backgroundColor: '#dd1500' }}>
        Booked Events
      </MDBCardHeader>
      <MDBCardBody>
        <MDBDataTable
          btn={btn || false}
          data={dataVals}
          striped
          bordered
          noBottomColumns
          responsive
          hover
          scrollX
        />
      </MDBCardBody>
    </MDBCard>
  );
});

DataTable.propTypes = propTypes;
DataTable.defaultProps = { btn: false };

export default DataTable;
