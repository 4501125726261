import React, { ReactElement } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';
import { VisibleSelectedStore } from 'Containers/Stores/visibleStores';
import SelectedReservation from 'Containers/Reservations/selectedReservation';
import { VisibleSelectedEvent } from 'Containers/Events/visibleEvents';
import { useStepper } from '_stores/stepperStore';
import withHooks from 'Utils/withHooks.jsx';
import ParentContainer from '_Ui/styledContainers/parentContainer';

const bool = PropTypes.bool;

const propTypes = {
  storeSelected: bool,
  eventSelected: bool,
  reservationSelected: bool,
};

type Props = InferProps<typeof propTypes>;

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 25px;
  justify-content: center;
`;

const SelectedSummary: React.FC<Props> = function SelectedSummary({
  storeSelected,
  eventSelected,
  reservationSelected,
}): ReactElement {
  return (
    <Wrapper>
      {storeSelected && <VisibleSelectedStore />}
      {eventSelected && <VisibleSelectedEvent />}
      {reservationSelected && <SelectedReservation />}
    </Wrapper>
  );
};

const mapHooksToProps = () => {
  const {
    state: { storeSelected, eventSelected, reservationSelected },
  } = useStepper();
  return { storeSelected, eventSelected, reservationSelected };
};

SelectedSummary.propTypes = propTypes;

export default withHooks(mapHooksToProps)(SelectedSummary);
