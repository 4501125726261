import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { SyntheticOnClick } from '_interfaces/OnClick';
import { Stores } from '_interfaces/Stores/Stores';
import ParentContainer from '_Ui/styledContainers/parentContainer';
import styles from './stores.module.scss';
import Store from './store';

const propTypes = {
  stores: PropTypes.array.isRequired,
  toggle: PropTypes.func,
};

type Props = InferProps<typeof propTypes> & { onClick: SyntheticOnClick };

const StoreList: React.FC<Props> = React.memo(function Storelist({ stores, onClick, toggle }) {
  return (
    <ParentContainer align="center">
      {stores &&
        stores.length > 0 &&
        stores.map((store: Stores) => (
          <Store
            key={store._id}
            store={store}
            onClick={(e) => {
              onClick(e);
              if (toggle) toggle();
            }}
          />
        ))}
    </ParentContainer>
  );
});

StoreList.propTypes = propTypes;
StoreList.defaultProps = { stores: [] };

export default StoreList;
