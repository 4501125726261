import React, { memo, ReactElement } from 'react';
import styled from 'styled-components';
import PropTypes, { InferProps } from 'prop-types';
import Text from 'Components/Text/text';
import ParentContainer from '_Ui/styledContainers/parentContainer';
import SelectedSummary from 'Containers/SelectedSummary/selectedSummary';
import fontSizes from 'Utils/_const/fontSizes';
import styles from './orderSummary.module.scss';

const propTypes = { cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired };

type Props = InferProps<typeof propTypes>;

const OrderSummary: React.FC<Props> = function OrderSummary({ cost }): ReactElement {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Text text={'order summary'} transform={'capitalize'} size={fontSizes.title} />
      </div>
      <SelectedSummary />
      <div className={styles.cost}>
        <Text text={'Total:'} size={fontSizes.header} bold={true} />
        <Text text={'$' + cost} size={fontSizes.header} className={styles['Avenir-Book']} />
      </div>
    </div>
  );
};

OrderSummary.propTypes = propTypes;

export default OrderSummary;
