import PropTypes, { InferProps } from 'prop-types';
import React, { ReactElement } from 'react';
import styled, { ThemeProvider } from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 32px;
  margin: 45px auto;
  justify-content: ${({ theme }) => theme.align || 'normal'};
  grid-template-columns: ${({ theme }) => theme.templateColumn || ''};
  grid-template-rows: ${({ theme }) => theme.templateRow || ''};
`;

const propTypes = {
  align: PropTypes.string,
  templateColumn: PropTypes.string,
  templateRow: PropTypes.string,
};

type Props = InferProps<typeof propTypes>;

const ParentContainer: React.FC<Props> = function ParentContainer({
  children,
  align,
  templateColumn,
  templateRow,
}): ReactElement {
  const theme = { align, templateColumn, templateRow };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>{children}</Wrapper>
    </ThemeProvider>
  );
};

export default ParentContainer;
